import { FC } from "react";
import { Typography } from "@mui/material";

import { DisplayImage, Header } from "components";

import image1 from "assets/images/18.jpg";
import image2 from "assets/images/10.jpg";
import image3 from "assets/images/19.jpg";
import image4 from "assets/images/13.jpg";

import scss from "./Services.module.scss";

const Services: FC = () => (
	<>
		<Header className={scss.header} background={image1}>
			<Typography variant="h1">SERVICES</Typography>
		</Header>
		<DisplayImage
			direction="left"
			background={image2}
			title="Patins">
			<Typography variant="body1">
				Pour la protection de vos chaussures, optez pour un patin sur mesure résistant et totalement adapté. Vos semelles en cuir seront aussi protégées de l’humidité et de l’usure prématurée liée à la marche.
			</Typography>
		</DisplayImage>
		<DisplayImage
			direction="right"
			background={image3}
			title="Talons">
			<Typography variant="body1">
				Réparer ou remplacer votre talon usé votre cordonnier est à votre disposition. Les matériaux utilisés sont de qualité et prolongent la durée de vie de vos chaussures pour femmes ou pour hommes.
			</Typography>
		</DisplayImage>
		<DisplayImage
			direction="left"
			background={image4}
			title="Clés">
			<Typography variant="body1">
				De la simple clé à la clé de sureté ou brevetée, votre cordonnier vous propose la reproduction de vos clés plates, vos clés à gorges et vos clés multipoints.
			</Typography>
		</DisplayImage>
	</>
);

export default Services;
