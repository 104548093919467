import { FC } from "react";
import { Outlet } from "react-router-dom";

import { NavBar, Footer } from "containers/App";

const Layout: FC = () => (
	<div>
		<NavBar />
		<Outlet />
		<Footer />
	</div>
);

export default Layout;
