import { FC } from "react";
import { Link } from "react-router-dom";
import { Grid, Typography } from "@mui/material";

import Time from "@mui/icons-material/AccessTime";
import EMail from "@mui/icons-material/Send";
import Phone from "@mui/icons-material/Phone";
import Room from "@mui/icons-material/Room";

import { Header } from "components";
import image1 from "assets/images/16.jpg";

import scss from "./Contact.module.scss";

const Contact: FC = () => (
	<>
		<Header className={scss.header} background={image1}>
			<Typography variant="h1">CONTACT ET HORAIRES</Typography>
		</Header>
		<Grid className={scss.contact} container maxWidth="lg" justifyContent="center"
			alignItems="center" marginX="auto" marginY={15}>
			<Grid item md={4} xs={12}>
				<Grid container justifyContent="center" alignItems="flex-start" direction="column" padding={6}>
					<Typography className={scss.icon} variant="body1" marginTop={2}>
						<Room fontSize="small" />
						<Link to="https://goo.gl/maps/jmBCS3DVjFSPBsor9" target="_blank">
							27 avenue du Vercors, 38240 Meylan
						</Link>
					</Typography>
					<Typography className={scss.icon} variant="body1" marginTop={2}>
						<Phone fontSize="small" />
						<Link to="tel:0476410535">04 76 41 05 35</Link>
					</Typography>
					<Typography className={scss.icon} variant="body1" marginTop={2}>
						<EMail fontSize="small" />
						<Link to="mailto:nardiello38@aol.com">nardiello38@aol.com</Link>
					</Typography>
					<Grid container direction="column" marginTop={2}>
						<Typography className={scss.icon} variant="body1">
							<Time fontSize="small" />
							<a>Horaires</a>
						</Typography>
						<Grid className={scss.horaires} container direction="column" marginX={4}>
							<Grid container>
								<Grid item xs={4}>Lundi</Grid>
								<Grid item xs={8}>Fermé</Grid>
							</Grid>
							<Grid container>
								<Grid item xs={4}>Mardi</Grid>
								<Grid item xs={8}>09h00 - 12h30 / 14h30 - 19h00</Grid>
							</Grid>
							<Grid container>
								<Grid item xs={4}>Mercredi</Grid>
								<Grid item xs={8}>09h00 - 12h30 / 14h30 - 19h00</Grid>
							</Grid>
							<Grid container>
								<Grid item xs={4}>Jeudi</Grid>
								<Grid item xs={8}>09h00 - 12h30 / 14h30 - 19h00</Grid>
							</Grid>
							<Grid container>
								<Grid item xs={4}>Vendredi</Grid>
								<Grid item xs={8}>09h00 - 12h30 / 14h30 - 19h00</Grid>
							</Grid>
							<Grid container>
								<Grid item xs={4}>Samedi</Grid>
								<Grid item xs={8}>09h00 - 12h00 / 14h00 - 17h00</Grid>
							</Grid>
							<Grid container>
								<Grid item xs={4}>Dimanche</Grid>
								<Grid item xs={8}>Fermé</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid item md={8} xs={12}>
				<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2811.0478585171895!2d5.771169676134847!3d45.20636705060501!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478af5b94b710753%3A0x4618c40473b8223e!2sCordonnerie%20Des%20Buclos!5e0!3m2!1sen!2sfr!4v1682181195157!5m2!1sen!2sfr" width="100%" height="450" style={{ border: 0 }} loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
			</Grid>
		</Grid>
	</>
);

export default Contact;
