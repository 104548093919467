import { CssBaseline, ThemeProvider } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom/client";

import { Theme } from "containers/App";
import Router from "router/router";
import "./styles/Main.scss";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

root.render(
	<React.StrictMode>
		<ThemeProvider theme={Theme}>
			<CssBaseline />
			<Router />
		</ThemeProvider>
	</React.StrictMode>
);
