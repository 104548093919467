import { FC } from "react";
import { Link } from "react-router-dom";
import { AppBar, Toolbar, Button, Grid, Fab } from "@mui/material";
import { css } from "@emotion/css";
import { useResponsive } from "@izui/react";
import classNames from "classnames";

import Facebook from "@mui/icons-material/Facebook";
import Instagram from "@mui/icons-material/Instagram";

import scss from "./NavBar.module.scss";

const NavBar: FC = () =>
{
	const { width, font, margin, wrap, fab } = useResponsive({
		desktop: { width: "80%", font: "1.2rem", margin: "1rem", wrap: "wrap", fab: "medium" },
		mobile: { width: "100%", font: "0.9rem", margin: "0", wrap: "wrap", fab: "small" }
	});

	const navbar = css`
		max-width: ${width};
	`;

	const buttons = css`
		flex-wrap: ${wrap};

		button {
			font-size: ${font};
			margin: ${margin};
		}
	`;

	return (
		<AppBar className={classNames(scss.navbar, navbar)} component="nav" color="transparent" position="static" elevation={0}>
			<Link to="/">
			</Link>
			<Toolbar className={classNames(scss.buttons, buttons)}>
				<Link to="/">
					<Button color="inherit">Accueil</Button>
				</Link>
				<Link to="/presentation">
					<Button color="inherit">Présentation</Button>
				</Link>
				<Link to="/services">
					<Button color="inherit">Services</Button>
				</Link>
				<Link to="/produits">
					<Button color="inherit">Produits</Button>
				</Link>
				<Link to="/contact">
					<Button color="inherit">Contact</Button>
				</Link>
			</Toolbar>
			<Grid justifyContent="flex-end" maxWidth={120} alignItems="center" className={scss.socials} container>
				<Link to="https://www.facebook.com/cordonneriedesbuclos" target="_blank">
					<Fab size={fab as any} color="inherit">
						<Facebook />
					</Fab>
				</Link>
				<Link to="https://www.instagram.com/cordonneriedesbuclos" target="_blank">
					<Fab size={fab as any} color="inherit">
						<Instagram />
					</Fab>
				</Link>
			</Grid>
		</AppBar>
	);
};

export default NavBar;
