import { FC } from "react";
import { Link } from "react-router-dom";
import { Button, Grid, Paper, Typography } from "@mui/material";

import { DisplayBox, DisplayCarousel, Header, Divider } from "components";
import { scrollToTop } from "utils";

import image1 from "assets/images/8.jpg";
import image2 from "assets/images/3.jpg";
import image3 from "assets/images/12.jpg";
import image4 from "assets/images/2.jpg";
import image5 from "assets/images/11.jpg";
import image6 from "assets/images/14.jpg";

import scss from "./Home.module.scss";

const Home: FC = () => (
	<>
		<Header className={scss.header} background={image1}>
			<Typography variant="h1" marginBottom={5}>CORDONNERIE DES BUCLOS</Typography>
			<Link to="/contact" onClick={scrollToTop}>
				<Button variant="contained">CONTACTEZ-NOUS</Button>
			</Link>
		</Header>
		<Grid className={scss.section} maxWidth="lg" container margin="auto">
			<Grid item xs={12}>
				<Typography variant="h2" align="center">Cordonnerie des Buclos, votre cordonnerie située à Meylan</Typography>
				<Divider />
			</Grid>
			<Grid item xs={12} md={6} padding={4}>
				<Typography variant="body1">La cordonnerie des Buclos est une cordonnerie traditionnelle située à Meylan et implantée depuis 20 ans. Le gérant Tony Nardiello a toujours fait ce métier depuis son CAP Cordonnerie obtenu à l’âge de 16 ans. Ce métier qu’il exerce avec passion assure des prestations artisanales de qualité. Votre cordonnerie vous aide dans le choix des prestations pour entretenir ou réparer vos chaussures.</Typography>
			</Grid>
			<Grid item xs={12} md={6} padding={4}>
				<Typography variant="body1">Du changement de talon au ressemelage, mais aussi les coutures : chaussures, sacs, fermetures éclair, toujours fait avec le même professionnalisme. Votre cordonnerie vous propose aussi toutes reproductions de clés, ainsi qu’un service gravure imprimerie et tampon.</Typography>
			</Grid>
		</Grid>
		<DisplayCarousel images={[image2, image6, image4]} direction="left">
			<Grid maxWidth="lg" container margin="auto" marginBottom={5}>
				<Grid item md={12} marginBottom={3}>
					<Typography variant="h2" align="left">Services</Typography>
				</Grid>
				<Grid item md={12}>
					<Typography variant="body1">Votre cordonnier met à votre disposition son service de pose de patins sur-mesure pour tout type de chaussures.</Typography>
				</Grid>
				<Grid container justifyContent="end" marginTop={4}>
					<Link to="/services" onClick={scrollToTop}>
						<Button variant="contained">VOIR PLUS</Button>
					</Link>
				</Grid>
			</Grid>
		</DisplayCarousel>
		<Typography className={scss.cordonnerie} variant="h2">CORDONNERIE DES BUCLOS</Typography>
		<DisplayBox className={scss.displayBox} background={image3} direction="left">
			<Typography color="white" variant="h2" align="center">
				Entretien
			</Typography>
			<Paper component="section" elevation={4} square>
				<Grid container justifyContent="center" alignItems="center" marginTop={6} padding={5}>
					<Typography>Votre cordonnier prend en charge l’entretien et la réparation de vos chaussures en adaptant son travail à la chaussure.</Typography>
				</Grid>
			</Paper>
		</DisplayBox>
		<Grid container justifyContent="center"
			alignItems="center" direction="column" marginY={30}>
			<Divider width={300} margin={20} />
		</Grid>
		<DisplayBox className={scss.displayBox} background={image5} direction="right">
			<Typography color="white" variant="h2" align="center">
				Produits
			</Typography>
			<Paper component="section" elevation={4} square>
				<Grid container justifyContent="center" alignItems="center" marginTop={6} padding={5}>
					<Typography>Votre cordonnerie moderne met à votre disposition une large gamme de produits d’entretien et de nettoyage pour tous types de chaussures.</Typography>
					<Grid container justifyContent="end" marginTop={4}>
						<Link to="/produits" onClick={scrollToTop}>
							<Button variant="contained">VOIR PLUS</Button>
						</Link>
					</Grid>
				</Grid>
			</Paper>
		</DisplayBox>
		<Grid container justifyContent="center"
			alignItems="center" direction="column" marginY={30}>
			<Divider width={300} margin={20} />
		</Grid>
	</>
);

export default Home;
