import { FC } from "react";
import { Typography } from "@mui/material";

import { DisplayImage, Header } from "components";

import image1 from "assets/images/11.jpg";
import image2 from "assets/images/4.jpg";
import image3 from "assets/images/5.jpg";
import image4 from "assets/images/6.jpg";
import image5 from "assets/images/7.jpg";
import image6 from "assets/images/20.jpg";

import scss from "./Produits.module.scss";

const Produits: FC = () => (
	<>
		<Header className={scss.header} background={image1}>
			<Typography variant="h1">PRODUITS</Typography>
		</Header>
		<DisplayImage
			direction="left"
			className={scss.image}
			background={image2}
			title="Nettoyant Saphir">
			<Typography variant="body1">
				Efficace pour redonner une vie à vos chaussures en frottant délicatement la surface. Ce lait nettoyant incolore s’adapte à tous les cuirs lisses, les chaussures, les vêtements, la maroquinerie et la bagagerie.
			</Typography>
		</DisplayImage>
		<DisplayImage
			direction="right"
			className={scss.image}
			background={image3}
			title="Rénovateur Saphir">
			<Typography variant="body1">
				Un spray pour rénover votre daim ou nubuck, existe en plusieurs colories.
			</Typography>
		</DisplayImage>
		<DisplayImage
			direction="left"
			className={scss.image}
			background={image4}
			title="Protector">
			<Typography variant="body1">
				Spray très simple d’utilisation imperméabilisant et anti-taches tout en laissant respirer les fibres. Application sur cuir, daim, nubuck, textile, sans silicone. L’imperméabilisant est un indispensable par temps de pluie, protéger et augmenter la durée de vie de vos chaussures.
			</Typography>
		</DisplayImage>
		<DisplayImage
			direction="right"
			className={scss.image}
			background={image5}
			title="Dubbin">
			<Typography variant="body1">
				Pour cuir gras. La graisse incolore imperméabilise et adoucit le cuir, il protège contre l’apparition des fissures du cuir.
			</Typography>
		</DisplayImage>
		<DisplayImage
			direction="left"
			className={scss.image}
			background={image6}
			title="Crème Canadian">
			<Typography variant="body1">
				Votre artisan cordonnier vous invite à découvrir une gamme complète de la marque Saphir. Une crème à base de cire d’abeille adaptée à tous les articles de cuir lisse.
			</Typography>
		</DisplayImage>
	</>
);

export default Produits;
