import { createTheme } from "@mui/material/styles";
import { responsiveFontSizes } from "@mui/material";

const Theme = responsiveFontSizes(createTheme({
	palette: {
		primary: {
			main: "rgb(19, 59, 75)",
		},
		secondary: {
			main: "#f50057",
		},
	},
	typography: {
		fontFamily: "Roboto, sans-serif",
		fontSize: 16,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		fontWeightBold: 700,
		h1: {
			fontFamily: "Oswald, sans-serif",
			fontSize: "4rem",
			fontWeight: 400,
			lineHeight: 1.2,
			letterSpacing: "8px",
		},
		h2: {
			fontFamily: "Oswald, sans-serif",
			fontSize: "1.75rem",
			fontWeight: 400,
			lineHeight: 1.2,
			letterSpacing: "1px",
		},
		h3: {
			fontSize: "3rem",
			fontWeight: 300,
			lineHeight: 1.2,
			letterSpacing: "0em",
		},
		h4: {
			fontSize: "1.2rem",
			fontWeight: 300,
			lineHeight: 1.2,
			letterSpacing: "0.00735em",
		},
		h5: {
			fontSize: "1rem",
			fontWeight: 300,
			lineHeight: 1.2,
			letterSpacing: "0em",
		},
		h6: {
			fontSize: "0.9rem",
			fontWeight: 300,
			lineHeight: 1.2,
			letterSpacing: "0.0075em",
		},
		body1: {
			fontSize: "0.95rem",
			fontWeight: 400,
			lineHeight: 1.6,
			letterSpacing: "0.00938em",
		},
		body2: {
			fontSize: "0.875rem",
			fontWeight: 300,
			lineHeight: 1.5,
			letterSpacing: "0.01071em",
		},
		button: {
			fontSize: "1rem",
			fontWeight: 300,
			lineHeight: 1.5,
			letterSpacing: "0.02857em",
			textTransform: "uppercase",
		},
	},
}));

export default Theme;
