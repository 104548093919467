(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@emotion/cache"), require("@emotion/react"), require("@emotion/styled"), require("@mui/material"), require("framer-motion"), require("react"), require("react-dom"), require("react-i18next"), require("react-router-dom"));
	else if(typeof define === 'function' && define.amd)
		define(["@emotion/cache", "@emotion/react", "@emotion/styled", "@mui/material", "framer-motion", "react", "react-dom", "react-i18next", "react-router-dom"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@emotion/cache"), require("@emotion/react"), require("@emotion/styled"), require("@mui/material"), require("framer-motion"), require("react"), require("react-dom"), require("react-i18next"), require("react-router-dom")) : factory(root["@emotion/cache"], root["@emotion/react"], root["@emotion/styled"], root["@mui/material"], root["framer-motion"], root["react"], root["react-dom"], root["react-i18next"], root["react-router-dom"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, function(__WEBPACK_EXTERNAL_MODULE__8836__, __WEBPACK_EXTERNAL_MODULE__5435__, __WEBPACK_EXTERNAL_MODULE__5518__, __WEBPACK_EXTERNAL_MODULE__901__, __WEBPACK_EXTERNAL_MODULE__2694__, __WEBPACK_EXTERNAL_MODULE__8156__, __WEBPACK_EXTERNAL_MODULE__7111__, __WEBPACK_EXTERNAL_MODULE__3238__, __WEBPACK_EXTERNAL_MODULE__6128__) {
return 