import { FC } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { Home, Presentation, Produits, Services, Contact } from "pages";
import Layout from "./layout/Layout";

const router = createBrowserRouter([
	{
		path: "/",
		element: <Layout />,
		children: [
			{
				path: "/",
				element: <Home />
			},
			{
				path: "/presentation",
				element: <Presentation />
			},
			{
				path: "/produits",
				element: <Produits />
			},
			{
				path: "/services",
				element: <Services />
			},
			{
				path: "/contact",
				element: <Contact />
			}
		]
	},
]);

const Router: FC = () => (
	<RouterProvider router={router} />
);

export default Router;
