import { FC } from "react";
import { Grid, Typography } from "@mui/material";

import { Divider } from "components";

const DisplayImage: FC<Props> = ({ className, background, direction, title, children }) => (
	<Grid className={className} container maxWidth="lg" marginX="auto"
		marginY={10} alignItems="center" justifyContent="center"
		direction={direction === "left" ? "row" : "row-reverse"}>
		<Grid item md={6} xs={12}>
			<Grid component="figure" container justifyContent="center" alignItems="center" margin={0}>
				<img width="90%" src={background} alt="" />
			</Grid>
		</Grid>
		<Grid item md={6} xs={12} padding={4}>
			<Divider width={40} margin="1rem 0" />
			<Typography variant="h2" marginBottom={4}>
				{title}
			</Typography>
			{children}
		</Grid>
	</Grid>
);

type Props = {
	background: string;
	direction: "left" | "right";
	title: string;
	children: React.ReactNode;
	className?: string;
};

export default DisplayImage;
