import { FC } from "react";
import { Grid, Typography } from "@mui/material";

import { DisplayImage, Divider, Header } from "components";
import image1 from "assets/images/15.jpg";
import image2 from "assets/images/17.jpg";

import scss from "./Presentation.module.scss";

const Presentation: FC = () => (
	<>
		<Header className={scss.header} background={image1}>
			<Typography variant="h1">PRÉSENTATION</Typography>
		</Header>
		<Grid className={scss.section} maxWidth="lg" container
			direction="column" alignItems="center" margin="auto">
			<Typography variant="h2" align="center">
				Faites appel à la cordonnerie des Buclos à Meylan
			</Typography>
			<Divider />
			<Typography maxWidth="md" variant="body1" align="center" padding={4} marginBottom={5}>
				À la Cordonnerie des Buclos, vous pouvez bénéficier d’un service de cordonnerie traditionnelle rapide et de qualité, respectant les matériaux d’origine et les techniques de restauration pour offrir une seconde vie à vos chaussures.
			</Typography>
		</Grid>
		<DisplayImage
			direction="left"
			background={image2}
			title="Tony Nardiello, artisan cordonnier à votre service">
			<Typography variant="body1">
				J’ai débuté mon métier de cordonnier en apprentissage rue de Strasbourg chez Mr Falbo en 1982. À la suite de ces 2 années j’ai obtenu mon CAP Cordonnier réparateur. Après plusieurs années d’expérience j’ai ouvert ma propre boutique à Meylan en 2003. J’exerce avec passion et minutie ce métier depuis toujours. Je suis aussi à votre disposition pour la reproduction de vos clés minute. Que ce soit pour vos clés plates, vos clés à gorges et vos clés multi points.
			</Typography>
		</DisplayImage>
	</>
);

export default Presentation;
