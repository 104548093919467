import { FC } from "react";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import EMail from "@mui/icons-material/Send";
import Phone from "@mui/icons-material/Phone";
import Room from "@mui/icons-material/Room";

import { scrollToTop } from "utils";

import scss from "./Footer.module.scss";

const Footer: FC = () => (
	<Box className={scss.footer} component="footer" padding={6}>
		<Grid container justifyContent="center">
			<Grid item md={4} xs={12} marginTop={5}>
				<Typography variant="h2">HORAIRES</Typography>
				<Divider />
				<Grid container direction="column">
					<Grid container>
						<Grid item xs={4}>Lundi</Grid>
						<Grid item xs={8}>Fermé</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={4}>Mardi</Grid>
						<Grid item xs={8}>09h00 - 12h30 / 14h30 - 19h00</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={4}>Mercredi</Grid>
						<Grid item xs={8}>09h00 - 12h30 / 14h30 - 19h00</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={4}>Jeudi</Grid>
						<Grid item xs={8}>09h00 - 12h30 / 14h30 - 19h00</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={4}>Vendredi</Grid>
						<Grid item xs={8}>09h00 - 12h30 / 14h30 - 19h00</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={4}>Samedi</Grid>
						<Grid item xs={8}>09h00 - 12h00 / 14h00 - 17h00</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={4}>Dimanche</Grid>
						<Grid item xs={8}>Fermé</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid item md={4} xs={12} marginTop={5}>
				<Typography variant="h2">CONTACT</Typography>
				<Divider />
				<Typography className={scss.icon} variant="body1" marginTop={2}>
					<Room fontSize="small" />
					<Link to="https://goo.gl/maps/jmBCS3DVjFSPBsor9" target="_blank">
						27 avenue du Vercors, 38240 Meylan
					</Link>
				</Typography>
				<Typography className={scss.icon} variant="body1" marginTop={2}>
					<Phone fontSize="small" />
					<Link to="tel:0476410535">04 76 41 05 35</Link>
				</Typography>
				<Typography className={scss.icon} variant="body1" marginTop={2}>
					<EMail fontSize="small" />
					<Link to="mailto:nardiello38@aol.com">nardiello38@aol.com</Link>
				</Typography>
			</Grid>
			<Grid item md={4} xs={12} marginTop={5}>
				<Typography variant="h2">LIENS RAPIDES</Typography>
				<Divider />
				<Grid container className={scss.links} justifyContent="center" direction="column">
					<Link to="/" onClick={scrollToTop}>
						<Button color="inherit">Accueil</Button>
					</Link>
					<Link to="/presentation" onClick={scrollToTop}>
						<Button color="inherit">Présentation</Button>
					</Link>
					<Link to="/services" onClick={scrollToTop}>
						<Button color="inherit">Services</Button>
					</Link>
					<Link to="/produits" onClick={scrollToTop}>
						<Button color="inherit">Produits</Button>
					</Link>
					<Link to="/contact" onClick={scrollToTop}>
						<Button color="inherit">Contact</Button>
					</Link>
				</Grid>
			</Grid>
		</Grid>
	</Box>
);

export default Footer;
