import { FC } from "react";
import { Grid } from "@mui/material";
import { css } from "@emotion/css";
import { useResponsive } from "@izui/react";
import classNames from "classnames";

import scss from "./DisplayBox.module.scss";

const DisplayBox: FC<Props> = ({ className, background, children, direction }) =>
{
	const { isDesktop } = useResponsive({
		desktop: { isDesktop: true },
		mobile: { isDesktop: false }
	});
	const left = direction === "left";

	const bg = css`
		background: rgb(19, 59, 75);
	`;

	const image = css`
		background: url(${background});
		background-size: cover;
	`;

	const container = css`
		position: absolute;
		${isDesktop ? `
			top: 65%;
			${left ? "left" : "right"}: 20%;
		` : `
			top: 65%;
		`}
	`;

	return (
		<Grid className={scss.display} container>
			<Grid direction={left ? "row" : "row-reverse"} container>
				<Grid className={image} item md={7} xs={12} />
				<Grid className={bg} item md={5} xs={12} />
			</Grid>
			<Grid className={classNames(className, container)} container direction="column"
				justifyContent="center" alignItems="center">
				{children}
			</Grid>
		</Grid>
	);
};

type Props = {
	className?: string;
	background?: string;
	direction: "left" | "right";
	children?: React.ReactNode;
};

export default DisplayBox;
