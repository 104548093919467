import { FC } from "react";
import { Carousel } from "react-responsive-carousel";
import { Box, Grid } from "@mui/material";
import { css } from "@emotion/css";
import { useBreakpoint } from "@izui/react";
import classNames from "classnames";

import scss from "./DisplayCarousel.module.scss";

const DisplayCarousel: FC<Props> = ({ className, children, images, direction }) =>
{
	const { width, backgroundWidth, marginGrid } = useBreakpoint({
		lg: {
			width: "50rem",
			backgroundWidth: "55%",
		},
		xs: {
			width: "unset",
			backgroundWidth: "0%",
			marginGrid: "4rem 2rem"
		}
	}, {});

	const left = direction === "left";

	const carousel = css`
		width: ${width};
		${left ? "margin-left: -2rem" : "margin-right: -2rem"};

		&::before {
			content: "";
			z-index: -1;
			position: absolute;
			background: rgb(19, 59, 75);
			width: ${backgroundWidth};
			height: 100%;
			top: 0;
			${left ? "left" : "right"}: 0;
		}
	`;

	const grid = css`
		padding: ${marginGrid};
	`;

	return (
		<Box className={classNames(scss.display, className)} marginTop={18}>
			<Grid className={scss.grid} direction={left ? "row" : "row-reverse"} maxWidth="lg"
				container margin="auto" alignItems="center">
				<Grid item lg={8} xs={12}>
					<Carousel className={classNames(scss.carousel, carousel)} showThumbs={false} showStatus={false}>
						{images.map((image, i) => (
							<div key={i}>
								<img src={image} alt="" />
							</div>
						))}
					</Carousel>
				</Grid>
				<Grid className={grid} item lg={4} xs={12}>
					{children}
				</Grid>
			</Grid>
		</Box>
	);
};

type Props = {
	className?: string;
	children?: React.ReactNode;
	images: string[];
	direction: "left" | "right";
};

export default DisplayCarousel;
