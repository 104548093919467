import { FC } from "react";
import classNames from "classnames";

import scss from "./Divider.module.scss";

const Divider: FC<Props> = ({ className, margin, width, height }) => (
	<hr className={classNames(scss.divider, className)} style={{ margin, width, height }} />
);

type Props = {
	className?: string;
	margin?: number | string;
	width?: number | string;
	height?: number | string;
};

export default Divider;
