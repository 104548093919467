import { FC } from "react";
import { Box, Container } from "@mui/system";
import classNames from "classnames";

import scss from "./Header.module.scss";

const Header: FC<Props> = ({ className, background, children }) => (
	<Box component="header" className={classNames(scss.header, className)}>
		<figure className={scss.background} style={{ backgroundImage: `url(${background})` }} />
		<Container className={scss.heading}>
			{children}
		</Container>
	</Box>
);

type Props = {
	className?: string;
	children?: React.ReactNode;
	background?: string;
};

export default Header;
